<template>
  <div class="mt-4 container order" style="min-height: 65vh;">
    <h2 class="text-start mb-3 order__title">訂單明細</h2>
    <div class="row" style="margin: 0px;">
      <div class="card border-0 text-start mb-4 col-12">
        <div class="card-body p-4">
          <h5 class="card-title mb-4 order__coursetitle">課程名稱：{{ course.course.name }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">課程售價</h6>
          <p class="card-text mb-4 order__price">$ {{ course.totalAmount }}</p>
          <strong class="d-inline-block mb-2">訂單狀態： <span class="fw-bold">{{ course.status }}</span></strong>
          <p class="mb-2">訂單編號：{{ course.merchantTradeNo }}</p>
          <p class="mb-2">購買時間：{{ course.merchantTradeDate }}</p>
          <p class="mb-2">交易完成時間：{{ course.merchantTradeDate }}</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center gx-3" style="margin: 0px;">
      <div class="card courses__card col-12 col-md-3" v-if="course" >
        <img src="../assets/images/defaultimg.png" alt="" v-if="!course.imgUrl || course.imgUrl == 'NotSet'">
        <img :src="course.imgUrl" class="card-img-top img-fluid courses__img" alt="..." v-if="course.imgUrl && course.imgUrl !== 'NotSet'">
        <div class="card-body text-start py-4">
          <h5 class="card-title courses__card-title fw-bold">
            {{ course.course.name.length > 30 ? `${course.course.name.substring(0, 30)}...` : course.course.name }}
          </h5>
          <h6 class="card-title text-muted">{{ course.course.author }}</h6>
        </div>
      </div>
      <div class="col-0 col-md-1"></div>
      <div class="card border-0 text-center mb-4 col-12 col-md-8 mt-4 mt-md-0">
        <div class="card-body p-4 d-flex flex-column align-items-center">
          <!-- <h5 class="card-title mb-4 order__coursetitle">課程名稱：0 SOLIDWORKS零件與組合件培訓教材tw</h5>
          <h6 class="card-subtitle mb-2 text-muted">課程售價</h6>
          <p class="card-text mb-4 order__price">$ 457</p>
          <strong class="d-inline-block mb-2">訂單狀態： 完成</strong>
          <p class="mb-2">訂單編號：1022011</p>
          <p class="mb-2">購買日期：2022/05/26</p> -->
          <template v-if="success == false">
            <button class="btn btn-main d-block mb-2">再試一次</button>
          </template>
          <template v-if="success == true">
            <button class="btn btn-main d-block mb-2" @click.prevent="$router.push(`/course_main/${course.course.id}`)">開始上課</button>
          </template>
          <img src="../assets/images/goLearn.svg" alt="" class="order__img">
        </div>
      </div>
    </div>
    <Meta :title ="title" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CourseCard from '../components/CourseCard';
const Swal = require('sweetalert2');
export default {
  name: 'OrderComplete',
  components: {
    CourseCard,
  },
  data() {
    return {
      courseId: '',
      course: '',
      success: true,
      title: '訂單資訊 - 元學堂',
    };
  },
  metaInfo() {
    return {
      title: `${this.course.course.name} - 訂單資訊`,
      meta: [
        { name: 'description', content: `${this.course.course.name} - 訂單資訊` },
        {
          property: 'og:title',
          content: `${this.course.course.name} - 訂單資訊`,
        },
        {
          property: 'og:description',
          content: `${this.course.course.name} - 訂單資訊` ,
        },
      ]
    }
  },
  methods: {
    getCourse() {
      this.$http.get(`${this.$API_PATH}/Course/Order/${this.courseId}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          this.course = res.data.data;
          this.$log.debug(this.course);
        }
      }).catch((err) => {
        const vm = this;
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        } else {
          this.$router.push('/error');
        }
        this.$log.debug(err.response);
      });
    },
    ...mapActions(['updateLoading', 'getUserAuth', 'getUserFromLocal', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  computed: {
    ...mapGetters(['userAuth', 'userState']),
  },
  created() {
    this.courseId = this.$route.params.id;
    this.getUserFromLocal();
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    if (this.userAuth.length !== 0 && this.userState.length !== 0) {
      this.getCourse();
    } else {
      this.$router.push('/login');
    }
  },
};
</script>
Footer
